import { sample } from 'effector';

import { getAllCategoriesQuery } from '@/shared/api/catalog/api';
import { pagesPath } from '@/shared/config/$path';
import { declarePage } from '@/shared/lib/effector/page';

export const notFoundPage = declarePage({
  pageType: pagesPath.$url().pathname,
});

sample({
  clock: notFoundPage.open,
  target: getAllCategoriesQuery.refresh,
});
