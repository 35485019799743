import Link from 'next/link';

import { MainLayout } from '@/widgets/layouts/main/main-layout';

import { Button } from '@/shared/components/ui/button';
import { Title } from '@/shared/components/ui/title';
import { Wrapper } from '@/shared/components/wrapper';

export const NotFoundPage = () => {
  return (
    <Wrapper className="mt-10 flex flex-col items-center justify-center gap-4">
      <Title type="h2">404: страница не найдена</Title>
      <p>Страница, которую вы ищете, не существует.</p>

      <Button asChild>
        <Link href="/">Вернуться на главную</Link>
      </Button>
    </Wrapper>
  );
};

NotFoundPage.Layout = MainLayout;
